import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import {
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  FileField,
  FileInput,
  Filter,
  ImageField,
  List,
  Pagination,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin';
import { CONTENT_STATUS } from '../constants';

const MoonCalendarImagesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <TextInput label="Title" source="title" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
  </Filter>
);

const MoonCalendarImagesActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 48]} {...props} />;

export const MoonCalendarImagesList = props => {
  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<MoonCalendarImagesFilter />} actions={<MoonCalendarImagesActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <TextField label="title" source="title" />
        <TextField label="description" source="description" />
        <ImageField title="Зураг" source="thumbnailImage" />
        <TextField label="Төлөв" source="status" />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const Title = ({ record }) => {
  const { id } = record;
  return <span>Zodiac MoonCalendarImages {record ? `"${id}"` : ''}</span>;
};

export const MoonCalendarImagesEdit = props => {
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput label="title" source="title" />
        <TextInput label="description" source="description" />
        <ImageField title="Зураг" source="thumbnailImage" />
        <FileInput label="Зураг" source="file" accept="image/*">
          <FileField source="image" title="image" />
        </FileInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};

export const MoonCalendarImagesCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <TextInput label="title" source="title" />
        <TextInput label="description" source="description" />
        <ImageField title="Зураг" source="thumbnailImage" />
        <FileInput label="Зураг" source="file" accept="image/*">
          <FileField source="image" title="image" />
        </FileInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
