import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  Pagination,
  FunctionField,
  // ReferenceInput,
  Filter,
  CreateButton,
  required, FormDataConsumer, Loading, RichTextField,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {CONTENT_STATUS, getSignName, ZODIAC_CHOICES} from '../constants';
import dataProvider from "../utils/dataProvider";
import PublishedButton from './PublishedButton';

const optionRenderer = (choice) => {
  return <RichTextField record={choice} source="name"/>
}

const fetchZTagData = () => {
  return dataProvider.getList('ztags', {
    pagination: {
      page: 1, perPage: 100,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.filter(item => {
        let ret = false;
        item.category.forEach(i => {
          if (i.category === 'monthly') {
            ret = true;
          }
        });
        return ret;
      }
    ).map(item => {
      let rank = 0;
      item.category.forEach(i => {
        if (i.category === 'monthly') {
          rank = i.rank;
        }
      });
      return {
        id: item.id,
        code: item.code,
        name: `${item?.icon} ${item?.name}`,
        rank,
      }
    }).sort((a, b) => a.rank - b.rank);
  });
}


const MonthlyFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
    <SelectInput
      label="Орд"
      source="zodiac"
      emptyText="Бүгд"
      choices={ZODIAC_CHOICES}
      alwaysOn
    />
  </Filter>
);

const MonthlyActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

// const getZtagName = ztag => `${ztag?.icon} ${ztag?.name}`;
const getFormattedDate = date => moment(date).format('YYYY-MM');
const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 48]} {...props} />;

export const MonthlyList = props => {
  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<MonthlyFilter />} actions={<MonthlyActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <FunctionField label="Орд" render={record => getSignName(record.zodiac)} sortable={false} />
        <FunctionField label="Эхлэх огноо" render={record => getFormattedDate(record.month)} />
        <TextField label="Төлөв" source="status" />
        <PublishedButton />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const Title = ({ record }) => {
  const { id } = record;
  return <span>Zodiac Monthly {record ? `"${id}"` : ''}</span>;
};

export const MonthlyEdit = props => {
  // const refresh = useRefresh();
  // ztags
  const [ztags, setZtags] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchZTagData().then(choices => {
        setZtags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        <DateInput label="Сар" source="month" validate={required()} />
        <ArrayInput fullWidth label="Агуулга" source="zodiacInformations">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <TextInput label="type" style={{display: 'none'}} value="MONTHLY" source={getSource('zContentType')} disabled />
                    <SelectInput optionText={optionRenderer} label="Төрөл" style={{minWidth: '200px'}} choices={ztags} source={getSource('ztagId')} />
                    <RichTextInput label="Тайлбар" source={getSource('description')} toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};

export const MonthlyCreate = props => {
  // const refresh = useRefresh();
  const [ztags, setZtags] = useState([]);
  const [infoDefaults, setInfoDefaults] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchZTagData().then(choices => {
        setInfoDefaults(choices.map(i => {
          return  {
            zContentType: 'MONTHLY',
            description : '',
            ztagId: i.id,
          }
        }));
        setZtags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        <DateInput label="Өдөр" source="month" validate={required()} />
        <ArrayInput fullWidth label="Агуулга" source="zodiacInformations" defaultValue={infoDefaults}>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <TextInput label="type" style={{display: 'none'}} value="MONTHLY" source={getSource('zContentType')} disabled />
                    <SelectInput optionText={optionRenderer} label="Төрөл" style={{minWidth: '200px'}} choices={ztags} source={getSource('ztagId')} />
                    <RichTextInput label="Тайлбар" source={getSource('description')} toolbar={[ ['bold', 'italic', 'underline', 'link'], [{'list': 'bullet'}] ]} />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
