import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  Filter,
  CreateButton,
  FunctionField,
  Pagination,
  required,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {CONTENT_STATUS, getSignName, ZODIAC_CHOICES} from '../constants';
import PublishedButton from './PublishedButton';

const AboutFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
    <SelectInput
      label="Орд"
      source="zodiac"
      emptyText="Бүгд"
      choices={ZODIAC_CHOICES}
      alwaysOn
    />
  </Filter>
);

const AboutActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 48]} {...props} />;

export const AboutList = props => {
  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<AboutFilter />} actions={<AboutActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <FunctionField label="Орд" render={record => getSignName(record.zodiac)} sortable={false} />
        <TextField label="Төлөв" source="status" />
        <PublishedButton />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const Title = ({ record }) => {
  const { zodiac } = record;
  return <span>Zodiac about {record ? `"${zodiac}"` : ''}</span>;
};

export const AboutEdit = props => {
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        {/*<ReferenceInput label="Орд" source="tagging.signId" reference="signs" validate={[required()]}>*/}
        {/*  <SelectInput optionText="name" />*/}
        {/*</ReferenceInput>*/}
        <ArrayInput fullWidth label="Ерөнхий мэдээлэл" source="zodiacAboutInformations">
          <SimpleFormIterator>
            <TextInput disabled label="zAboutId" source="id" />
            <TextInput label="Гарчиг" source="title" />
            <TextInput label="Тайлбар" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <RichTextInput label="Агуулга" source="content" />
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};

export const AboutCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        {/*<ReferenceInput label="Орд" source="tagging.signId" reference="signs" validate={[required()]}>*/}
        {/*  <SelectInput optionText="name" />*/}
        {/*</ReferenceInput>*/}
        <ArrayInput fullWidth label="Ерөнхий мэдээлэл" source="zodiacAboutInformations">
          <SimpleFormIterator>
            <TextInput label="Гарчиг" source="title" />
            <TextInput label="Тайлбар" source="description" />
          </SimpleFormIterator>
        </ArrayInput>
        <RichTextInput label="Агуулга" source="content" />
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
