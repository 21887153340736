import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  FunctionField,
  Pagination,
  Filter,
  CreateButton,
  required,
  Loading,
  FormDataConsumer, RichTextField,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { Box } from '@material-ui/core';
import { CONTENT_STATUS } from '../constants';
import dataProvider from "../utils/dataProvider";

const optionRenderer = (choice) => {
  return <RichTextField record={choice} source="name" />
}

const fetchMoonCalendarTypes = () => {
  return dataProvider.getList('moonCalendarTypes', {
    pagination: {
      page: 1, perPage: 100,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.map(item => {
      return {
        id: item.id,
        name: item?.title,
      }
    });
  });
}

const fetchMoonCalendarImages = () => {
  return dataProvider.getList('moonCalendarImages', {
    pagination: {
      page: 1, perPage: 101,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.map(item => {
      return {
        id: item.id,
        name: item?.title,
        image: item?.thumbnailImage,
      }
    });
  });
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

const MoonCalendarFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
    <DateInput
      label="Сар"
      source="month"
      alwaysOn
    />
  </Filter>
);

const MoonCalendarActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

// const getZtagName = ztag => `${ztag?.icon} ${ztag?.title}`;
const getFormattedDate = date => moment(date).format('YYYY-MM');
const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 48]} {...props} />;

export const MoonCalendarList = props => {
  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<MoonCalendarFilter />} actions={<MoonCalendarActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <FunctionField label="Сар" render={record => getFormattedDate(record.month)} />
        <TextField label="Төлөв" source="status" />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const Title = ({ record }) => {
  const { id } = record;
  return <span>Zodiac MoonCalendar {record ? `"${id}"` : ''}</span>;
};

export const MoonCalendarEdit = props => {
  const [loading, setLoading] = useState(true);
  const [moonCalendarTypes, setMoonCalendarTypes] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const [moonCalendarImages, setMoonCalendarImages] = useState([]);
  useEffect(() => {
    fetchMoonCalendarTypes().then(choices => {
      setMoonCalendarTypes(choices);
      setLoading(false);
    });
    fetchMoonCalendarImages().then(choices => {
      setMoonCalendarImages(choices);
      setLoadingImages(false);
    });
  }, [])
  if (loading || loadingImages) return <Loading />;
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <DateInput label="Сар" source="month" validate={required()} />
        <ArrayInput fullWidth label="Агуулга" source="zodiacMoonCalendarDays">
          <SimpleFormIterator disableRemove disableAdd>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <Box display="flex">
                      <DateInput label="Өдөр" source={getSource('day')} disabled />
                      <TextInput label="Гараг" source={getSource('dayOfWeek')} disabled />
                      <SelectInput
                        optionText={optionRenderer}
                        style={{ minWidth: '250px' }}
                        label="Төрөл" source={getSource('types')}
                        validate={required()}
                        choices={moonCalendarTypes}
                        parse={value => !value ? null : [value]}
                      />
                      <SelectInput
                        optionText={optionRenderer}
                        style={{ minWidth: '250px' }}
                        label="Зураг" source={getSource('images')}
                        validate={required()}
                        choices={moonCalendarImages}
                        parse={value => !value ? null : [value]}
                      />
                    </Box>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};
const MoonCalendarInput = props => {
  const { values: {
    month
  } } = useFormState();
  const { change } = useForm();
  const { types, images } = props;
  const [loading, setLoading] = useState(true);
  const [choices, setChoices] = useState([]);
  const [choiceImages, setChoiceImages] = useState([]);
  useEffect(() => {
    setLoading(true);
    if (month) {
      setChoices(types);
      setChoiceImages(images);
      const [yyyy, mm] = month.split('-');
      const days = getDaysInMonth(yyyy, mm);
      const values = [];
      for (let i = 1; i <= days; i += 1) {
        const d = new Date(yyyy, mm - 1, i, 8);
        values.push({
          day: d.toISOString(),
          dayOfWeek: { 0: 'Ням', 1: 'Даваа', 2: 'Мягмар', 3: 'Лхагва', 4: 'Пүрэв', 5: 'Баасан', 6: 'Бямба' }[d.getDay()],
          types: [],
        });
      }
      change('zodiacMoonCalendarDays', values);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [month, types, images, change])
  if (loading) return <Loading />;
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator disableRemove disableAdd>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, getSource, scopedFormData }) => {
            return (
              <>
                <Box display="flex">
                  <DateInput label="Өдөр" source={getSource('day')} disabled />
                  <TextInput label="Гараг" source={getSource('dayOfWeek')} disabled />
                  <SelectInput
                    optionText={optionRenderer}
                    style={{ minWidth: '250px' }}
                    label="Төрөл"
                    source={getSource('types')}
                    choices={choices}
                    validate={required()}
                    parse={value => !value ? null : [value]}
                  />
                  <SelectInput
                    optionText={optionRenderer}
                    style={{ minWidth: '250px' }}
                    label="Зураг"
                    source={getSource('images')}
                    choices={choiceImages}
                    validate={required()}
                    parse={value => !value ? null : [value]}
                  />
                </Box>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const MoonCalendarCreate = props => {
  const [loading, setLoading] = useState(true);
  const [moonCalendarTypes, setMoonCalendarTypes] = useState([]);
  const [loadingImages, setLoadingImages] = useState(true);
  const [moonCalendarImages, setMoonCalendarImages] = useState([]);
  useEffect(() => {
    fetchMoonCalendarTypes().then(choices => {
      setMoonCalendarTypes(choices);
      setLoading(false);
    });
    fetchMoonCalendarImages().then(choices => {
      setMoonCalendarImages(choices);
      setLoadingImages(false);
    });
  }, [])
  if (loading || loadingImages) return <Loading />;
  return (
    <Create {...props}>
      <SimpleForm>
        <DateInput label="Сар" source="month" validate={required()} />
        <MoonCalendarInput
          fullWidth
          label="Агуулга"
          source="zodiacMoonCalendarDays"
          types={moonCalendarTypes}
          images={moonCalendarImages}
        />
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
