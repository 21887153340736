import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';

const PublishedButton = (props) => {
  const { record, resource} = props;
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.put(`${resource}/${record.id}`, { data: {
      ...record,
      status: record.status === "draft" ? "published" : "draft",
    } })
      .catch((e) => {
        console.log(e)
        notify('Error: Status hasn\'nt been changed', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };

  return <Button
    onClick={handleClick}
    label={record.status === "draft" ? "Нийтлэх" : "Драфтлах"}
    disabled={loading}
  />;
};

export default PublishedButton;
