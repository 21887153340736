import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  CloneButton,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  FunctionField,
  // ReferenceArrayInput,
  Pagination,
  SelectArrayInput,
  Filter,
  CreateButton,
  required,
  Loading,
  FormDataConsumer, RichTextField,
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { Box } from '@material-ui/core';
// import RichTextInput from 'ra-input-rich-text';
import {CONTENT_STATUS, getSignName, ZODIAC_CHOICES} from '../constants';
import dataProvider from "../utils/dataProvider";
import PublishedButton from './PublishedButton';

const optionRenderer = (choice) => {
  return <RichTextField record={choice} source="name"/>
}

const fetchMonthlyTypesData = () => {
  return dataProvider.getList('monthlyTypes', {
    pagination:{
      page: 1, perPage: 100,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.map(item => {
      return {
        id: item.id,
        name: `${item?.icon}`,
      }
    });
  });
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

const MonthlyStatusFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
    <SelectInput
      label="Орд"
      source="zodiac"
      emptyText="Бүгд"
      choices={ZODIAC_CHOICES}
      alwaysOn
    />
  </Filter>
);

const MonthlyStatusActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

// const getZtagName = ztag => `${ztag?.icon} ${ztag?.title}`;
const getFormattedDate = date => moment(date).format('YYYY-MM');
const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 48]} {...props} />;

export const MonthlyStatusList = props => {
  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<MonthlyStatusFilter />} actions={<MonthlyStatusActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <FunctionField label="Орд" render={record => getSignName(record.zodiac)} sortable={false} />
        <FunctionField label="Эхлэх огноо" render={record => getFormattedDate(record.month)} />
        <TextField label="Төлөв" source="status" />
        <PublishedButton />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const Title = ({ record }) => {
  const { id } = record;
  return <span>Zodiac MonthlyStatus {record ? `"${id}"` : ''}</span>;
};

export const MonthlyStatusEdit = props => {
  const [loading, setLoading] = useState(true);
  const [monthlyTypes, setMonthlyTypes] = useState([]);
  useEffect(() => {
    fetchMonthlyTypesData().then(choices => {
      setMonthlyTypes(choices);
      setLoading(false);
    });
  }, [])
  if (loading) return <Loading />;
  return (
    <Edit title={<Title />} {...props}>
      <SimpleForm>
        <CloneButton />
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        <DateInput label="Сар" source="month" validate={required()} disabled />
        <ArrayInput fullWidth label="Агуулга" source="zodiacMonthlyTypes">
          <SimpleFormIterator disableRemove disableAdd>
            <FormDataConsumer subscription={{ values: true }}>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <Box display="flex">
                      <DateInput label="Өдөр" source={getSource('day')} disabled />
                      <TextInput label="Гараг" source={getSource('dayOfWeek')} disabled />
                      <SelectArrayInput optionText={optionRenderer} style={{minWidth: '300px'}}  label="Төрөл" source={getSource('types')} validate={required()} choices={monthlyTypes}/>
                    </Box>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};
const MonthlyTypeInput = props => {
  const { values: {
    month
  }} = useFormState();
  const { change } = useForm();
  const { types } = props;
  const [loading, setLoading] = useState(true);
  const [choices, setChoices] = useState([]);
  useEffect(() => {
    setLoading(true);
    if (month) {
      setChoices(types);
      const [yyyy, mm] = month.split('-');
      const days = getDaysInMonth(yyyy, mm);
      const values = [];
      for (let i = 1; i <= days; i += 1) {
        const d = new Date(yyyy, mm - 1, i, 8);
        values.push({
          day: d.toISOString(),
          dayOfWeek: { 0: 'Ням', 1: 'Даваа', 2: 'Мягмар', 3: 'Лхагва', 4: 'Пүрэв', 5: 'Баасан', 6: 'Бямба' }[d.getDay()],
          types: [],
        });
      }
      change('zodiacMonthlyTypes', values);
    }
    setTimeout(()=>{
      setLoading(false);
    }, 1000);
  }, [month, types, change])
  if (loading) return <Loading />;
  return (
    <ArrayInput {...props}>
      <SimpleFormIterator disableRemove disableAdd>
        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, getSource, scopedFormData }) => {
            return (
              <>
                <Box display="flex">
                  <DateInput label="Өдөр" source={getSource('day')} disabled />
                  <TextInput label="Гараг" source={getSource('dayOfWeek')} disabled />
                  <SelectArrayInput
                    optionText={optionRenderer}
                    style={{minWidth: '300px'}}
                    label="Төрөл"
                    source={getSource('types')}
                    validate={required()}
                    choices={choices}
                  />
                </Box>
              </>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const MonthlyStatusCreate = props => {
  const [loading, setLoading] = useState(true);
  const [monthlyTypes, setMonthlyTypes] = useState([]);
  useEffect(() => {
    fetchMonthlyTypesData().then(choices => {
      setMonthlyTypes(choices);
      setLoading(false);
    });
  }, [])
  if (loading) return <Loading />;
  return (
    <Create {...props}>
      <SimpleForm>
        <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
        <DateInput label="Сар" source="month" validate={required()} />
        <MonthlyTypeInput fullWidth label="Агуулга" source="zodiacMonthlyTypes" types={monthlyTypes} />
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
