export const COUNTRY_NAMES = [
  { id: 'USA', name: 'Америк' },
  { id: 'Germany', name: 'Герман' },
  { id: 'Australia', name: 'Австрали' },
  { id: 'South Korea', name: 'Солонгос' },
  { id: 'Japan', name: 'Япон' },
  { id: 'UK', name: 'Англи' },
  { id: 'China', name: 'Хятад' },
  { id: 'Russia', name: 'Орос' },
  { id: 'Taiwan', name: 'Тайвань' },
  { id: 'Canada', name: 'Канад' },
];

export const SUBSCRIPTION_TYPES = {
  FREE: '0 months',
  ONE: '1 months',
  TWO: '2 months',
  THREE: '3 months',
  FOUR: '4 months',
  FIVE: '5 months',
  SIX: '6 months',
  SEVEN: '7 months',
  EIGHT: '8 months',
  NINE: '9 months',
  TEN: '10 months',
  ELEVEN: '11 months',
  TWELVE: '12 months',
  FORTNIGHT: '14 days',
  WEEK: '7 days',
};

export const PLAN_CHOICES = [
  { id: SUBSCRIPTION_TYPES.ONE, name: '1 сар'},
  // { id: SUBSCRIPTION_TYPES.TWO, name: '2 сар' },
  { id: SUBSCRIPTION_TYPES.THREE, name: '3 сар' },
  // { id: SUBSCRIPTION_TYPES.FOUR, name: '4 сар' },
  // { id: SUBSCRIPTION_TYPES.FIVE, name: '5 сар' },
  // { id: SUBSCRIPTION_TYPES.SIX, name: '6 сар' },
  // { id: SUBSCRIPTION_TYPES.SEVEN, name: '7 сар' },
  // { id: SUBSCRIPTION_TYPES.EIGHT, name: '8 сар' },
  // { id: SUBSCRIPTION_TYPES.NINE, name: '9 сар' },
  // { id: SUBSCRIPTION_TYPES.TEN, name: '10 сар' },
  // { id: SUBSCRIPTION_TYPES.ELEVEN, name: '11 сар' },
  // { id: SUBSCRIPTION_TYPES.TWELVE, name: '12 сар' },
  // { id: SUBSCRIPTION_TYPES.FORTNIGHT, name: '14 өдөр' },
  // { id: SUBSCRIPTION_TYPES.WEEK, name: '7 өдөр' },
  { id: SUBSCRIPTION_TYPES.FREE, name: 'Үнэгүй' },
];

export const PURCHASE_TYPES = [
  { id: 'FREE', name: 'Free'},
  { id: 'BASIC', name: 'Basic' },
  { id: 'PREMIUM', name: 'Premium' },
];

export const ZODIACS = {
  RAM: 'Ram',
  BULL: 'Bull',
  TWINS: 'Twins',
  CRAB: 'Crab',
  LION: 'Lion',
  VIRGIN: 'Virgin',
  BALANCE: 'Balance',
  SCORPION: 'Scorpion',
  ARCHER: 'Archer',
  GOAT: 'Goat',
  WATER: 'Water',
  FISH: 'Fish',
};

// export const ZODIAC_CHOICES = [
//   { id: ZODIACS.RAM, name: 'Ram'},
//   { id: ZODIACS.BULL, name: 'Bull' },
//   { id: ZODIACS.TWINS, name: 'Twins' },
//   { id: ZODIACS.CRAB, name: 'Crab' },
//   { id: ZODIACS.LION, name: 'Lion' },
//   { id: ZODIACS.VIRGIN, name: 'Virgin' },
//   { id: ZODIACS.BALANCE, name: 'Balance' },
//   { id: ZODIACS.SCORPION, name: 'Scorpion' },
//   { id: ZODIACS.ARCHER, name: 'Archer' },
//   { id: ZODIACS.GOAT, name: 'Goat' },
//   { id: ZODIACS.WATER, name: 'Water' },
//   { id: ZODIACS.FISH, name: 'Fish' },
// ];

export const ZCONTENT_TYPES = [
  { id: 'daily', name: 'Daily'},
  { id: 'weekly', name: 'Weekly'},
  { id: 'monthly', name: 'Monthly'},
  { id: 'yearly', name: 'Yearly'},
  { id: 'moonCalendarType', name: 'Moon Calendar Type'},
  { id: 'compatibility', name: 'Compatibility'},
];

export const ZODIAC_CHOICES = [
  { id : 'ARIES', name: 'Хонь' },
  { id : 'TAURUS', name: 'Үхэр' },
  { id : 'GEMINI', name: 'Ихэр' },
  { id : 'CANCER', name: 'Мэлхий' },
  { id : 'LEO', name: 'Арслан' },
  { id : 'VIRGO', name: 'Охин' },
  { id : 'LIBRA', name: 'Жинлүүр' },
  { id : 'SCORPIO', name: 'Хилэнц' },
  { id : 'SAGITTARIUS', name: 'Нум' },
  { id : 'CAPRICORN', name: 'Матар' },
  { id : 'AQUARIUS', name: 'Хумх'},
  { id : 'PISCES', name: 'Загас'},
];

export const MOON_CALENDAR = [
  { id : 'MC1', name: 'Билгийн тооллын 1' },
  { id : 'MC2', name: 'Билгийн тооллын 2' },
  { id : 'MC3', name: 'Билгийн тооллын 3' },
  { id : 'MC4', name: 'Билгийн тооллын 4' },
  { id : 'MC5', name: 'Билгийн тооллын 5' },
  { id : 'MC6', name: 'Билгийн тооллын 6' },
  { id : 'MC7', name: 'Билгийн тооллын 7' },
  { id : 'MC8', name: 'Билгийн тооллын 8' },
  { id : 'MC9', name: 'Билгийн тооллын 9' },
  { id : 'MC10', name: 'Билгийн тооллын 10' },
  { id : 'MC11', name: 'Билгийн тооллын 11' },
  { id : 'MC12', name: 'Билгийн тооллын 12' },
  { id : 'MC13', name: 'Билгийн тооллын 13' },
  { id : 'MC14', name: 'Билгийн тооллын 14' },
  { id : 'MC15', name: 'Билгийн тооллын 15' },
  { id : 'MC16', name: 'Билгийн тооллын 16' },
  { id : 'MC17', name: 'Билгийн тооллын 17' },
  { id : 'MC18', name: 'Билгийн тооллын 18' },
  { id : 'MC19', name: 'Билгийн тооллын 19' },
  { id : 'MC20', name: 'Билгийн тооллын 20' },
  { id : 'MC21', name: 'Билгийн тооллын 21' },
  { id : 'MC22', name: 'Билгийн тооллын 22' },
  { id : 'MC23', name: 'Билгийн тооллын 23' },
  { id : 'MC24', name: 'Билгийн тооллын 24' },
  { id : 'MC25', name: 'Билгийн тооллын 25' },
  { id : 'MC26', name: 'Билгийн тооллын 26' },
  { id : 'MC27', name: 'Билгийн тооллын 27' },
  { id : 'MC28', name: 'Билгийн тооллын 28' },
  { id : 'MC29', name: 'Билгийн тооллын 29' },
  { id : 'MC30', name: 'Билгийн тооллын 30' },
];

export const ZODIAC_NAMES = {
  'ARIES': 'Хонь',
  'TAURUS': 'Үхэр',
  'GEMINI': 'Ихэр',
  'CANCER': 'Мэлхий',
  'LEO': 'Арслан',
  'VIRGO': 'Охин',
  'LIBRA': 'Жинлүүр',
  'SCORPIO': 'Хилэнц',
  'SAGITTARIUS': 'Нум',
  'CAPRICORN': 'Матар',
  'AQUARIUS': 'Хумх',
  'PISCES': 'Загас',
};

export const getSignName = (key) => {
  if (ZODIAC_NAMES.hasOwnProperty(key)) {
    return ZODIAC_NAMES[key];
  }
  return key;
}

export const TAG_TYPES = [
  { id: 'COUNTRY', name: 'Улс'},
  { id: 'LANGUAGE', name: 'Хэл' },
  { id: 'LEVEL', name: 'Түвшин' },
  { id: 'VIDEO_CONTENT_TYPE', name: 'Видео контентын төрөл' },
];

export const SOCIAL_TYPES = [
  {id:'FB', name:'FB' },
  {id:'GOOGLE', name:'GOOGLE'},
  {id:'MANUAL', name:'MANUAL'},
  {id:'APPLE',name: 'APPLE'},
  {id:'ANONYMOUS', name:'ANONYMOUS'},
];

export const USER_TYPES = [
  { id: 'FREE', name: 'FREE' },
  { id: 'PAID', name: 'PAID' },
  { id: 'STAFF', name: 'STAFF' },
  { id: 'MENTOR', name: 'MENTOR' },
  { id: 'ECC', name: 'ECC' },
  { id: 'SPECIAL_GUEST', name: 'SPECIAL_GUEST' },
];

export const SUBSCRIPTION_STATUSES = [
  { id: 'FREE', name: 'FREE' },
  { id: 'PAID', name: 'PAID' },
];

export const MENTORING_DURATION = [
  {id: 15, name: '15 минут'},
  {id: 30, name:'30 минут' },
  {id: 60, name:'1 цаг'},
]

export const COMPATIBILITY_SCORE = [
  {id: 1, name: '1/5'},
  {id: 2, name: '2/5'},
  {id: 3, name: '3/5'},
  {id: 4, name: '4/5'},
  {id: 5, name: '5/5'},
]

export const CONTENT_STATUS = [
  { id: 'published', name: 'нийтлэсэн' },
  { id: 'draft', name: 'драфтласан' },
]

export const PURCHASE_ORDER_TAGS = [
  { id: 'TOMYO', name: 'TOMYO' },
  { id: 'SUPER_UP', name: 'SUPER_UP' },
];

export const VIDEO_TYPES = [
  { id: 'course', name: 'Course' },
  { id: 'alumniVoice', name: 'Alumni Voice' },
  { id: 'outliers', name: 'Outliers' },
  { id: 'worldly', name: 'Worldly' },
  { id: 'advices', name: 'Advices' }
]

export const LANGUAGE_LEVELS = [
  { id: 'A1', name: 'A1' },
  { id: 'A2', name: 'A2' },
  { id: 'B1', name: 'B1' },
  { id: 'B2', name: 'B2' },
  { id: 'C1', name: 'C1' },
  { id: 'C2', name: 'C2' }
]

export const APP_TYPES = [
  { id: 'world', name: 'World' },
  { id: 'lingo', name: 'Lingo' },
  { id: 'listening', name: 'Listening' },
  { id: 'reading', name: 'Reading' },
  { id: 'writing', name: 'Writing' },
  { id: 'speaking', name: 'Speaking' },
]

export const MONTH_TYPES = [
  { id: 1, name: '1-р сар' },
  { id: 2, name: '2-р сар' },
  { id: 3, name: '3-р сар' },
  { id: 4, name: '4-р сар' },
  { id: 5, name: '5-р сар' },
  { id: 6, name: '6-р сар' },
  { id: 7, name: '7-р сар' },
  { id: 8, name: '8-р сар' },
  { id: 9, name: '9-р сар' },
  { id: 10, name: '10-р сар' },
  { id: 11, name: '11-р сар' },
  { id: 12, name: '12-р сар' },
]
