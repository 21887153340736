import { Box, Typography } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import RichTextInput from 'ra-input-rich-text';
import React, { useEffect, useState } from 'react';
import {
  ArrayInput,
  CloneButton,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FunctionField,
  List,
  Loading,
  NumberInput,
  Pagination,
  RichTextField,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  maxValue,
  minValue,
  required
} from 'react-admin';
import { COMPATIBILITY_SCORE, CONTENT_STATUS, ZODIAC_CHOICES, getSignName } from '../constants';
import dataProvider from "../utils/dataProvider";

const fetchZTagData = () => {
  return dataProvider.getList('ztags', {
    pagination: {
      page: 1, perPage: 100,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.filter(item => {
      let ret = false;
      item.category.forEach(i => {
        if (i.category === 'compatibility') {
          ret = true;
        }
      });
      return ret;
    }
    ).map(item => {
      let rank = 0;
      item.category.forEach(i => {
        if (i.category === 'compatibility') {
          rank = i.rank;
        }
      });
      return {
        id: item.id,
        code: item.code,
        name: `${item?.icon} ${item?.name}`,
        rank,
      }
    }).sort((a, b) => a.rank - b.rank);
  });
}

const CompatibilityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn />
    <SelectInput
      label="Төлөв"
      source="status"
      emptyText="Бүгд"
      choices={CONTENT_STATUS}
      alwaysOn
    />
    <SelectInput
      label="Орд"
      source="zodiac"
      emptyText="Бүгд"
      choices={ZODIAC_CHOICES}
      alwaysOn
    />
    <SelectInput
      label="Орд 2"
      source="zodiac2"
      emptyText="Бүгд"
      choices={ZODIAC_CHOICES}
      alwaysOn
    />
  </Filter>
);

const CompatibilityActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const RowPagination = props => <Pagination rowsPerPageOptions={[24, 48, 72]} {...props} />;

export const CompatibilityList = props => {
  return (
    <List {...props} perPage={48} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<CompatibilityFilter />} actions={<CompatibilityActions props={props} />}>
      <Datagrid optimized>
        <TextField label="id" source="id" />
        <FunctionField label="Орд" render={record => getSignName(record.zodiac)} sortable={false} />
        <FunctionField label="Орд 2" render={record => getSignName(record.zodiac2)} sortable={false} />
        <TextField label="Төлөв" source="status" />
        <EditButton />
      </Datagrid>
    </List>
  )
};

const optionRenderer = (choice) => {
  return <RichTextField record={choice} source="name" />
}

export const CompatibilityEdit = props => {
  const [ztags, setZtags] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchZTagData().then(choices => {
        setZtags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;

  return (
    <Edit {...props}>
      <SimpleForm>
        <CloneButton />
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="zodiac" label="Орд 1" choices={ZODIAC_CHOICES} validate={required()} />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="zodiac2" label="Орд 2" choices={ZODIAC_CHOICES} validate={required()} />
          </Box>
        </Box>
        <Box>
          <Typography variant="caption">Ерөнхий зохицол</Typography>
          <NumberInput label="Хувь" source="generalPercentage" validate={[
            required(), minValue(0, 'Багадаа 0%'), maxValue(100, 'Ихдээ 100%')
          ]} style={{ minWidth: '300px' }} />
        </Box>
        <Box display="flex" alignItems="center" width="100%">
          <SelectInput
            label="Хайр дурлал"
            source="love"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Ажил хэрэг"
            source="career"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Гэрлэлт"
            source="marriage"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Секс"
            source="sex"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Нөхөрлөл"
            source="friendship"
            choices={COMPATIBILITY_SCORE}
          />
        </Box>
        <ArrayInput fullWidth label="Агуулга" source="zodiacInformations">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <TextInput label="type" style={{ display: 'none' }} value="DAILY" source={getSource('zContentType')} disabled />
                    <SelectInput optionText={optionRenderer} label="Төрөл" style={{ minWidth: '200px' }} choices={ztags} source={getSource('ztagId')} />
                    <RichTextInput label="Тайлбар" source={getSource('description')} toolbar={[['bold', 'italic', 'underline', 'link'], [{ 'list': 'bullet' }]]} />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Edit>
  )
};

export const CompatibilityCreate = props => {
  const [ztags, setZtags] = useState([]);
  const [infoDefaults, setInfoDefaults] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchZTagData().then(choices => {
        setInfoDefaults(choices.map(i => {
          return {
            zContentType: 'COMPATIBILITY',
            description: '',
            ztagId: i.id,
          }
        }));
        setZtags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;
  return (
    <Create {...props}>
      <SimpleForm>
        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="zodiac" label="Орд 1" choices={ZODIAC_CHOICES} validate={required()} />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <SelectInput source="zodiac2" label="Орд 2" choices={ZODIAC_CHOICES} validate={required()} />
          </Box>
        </Box>
        <Box>
          <Typography variant="caption">Ерөнхий зохицол</Typography>
          <NumberInput label="Хувь" source="generalPercentage" validate={[
            required(), minValue(0, 'Багадаа 0%'), maxValue(100, 'Ихдээ 100%')
          ]} style={{ minWidth: '300px' }} />
        </Box>
        <Box display="flex" alignItems="center" width="100%">
          <SelectInput
            label="Хайр дурлал"
            source="love"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Ажил хэрэг"
            source="career"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Гэрлэлт"
            source="marriage"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Секс"
            source="sex"
            choices={COMPATIBILITY_SCORE}
            style={{ marginRight: 12 }}
          />
          <SelectInput
            label="Нөхөрлөл"
            source="friendship"
            choices={COMPATIBILITY_SCORE}
          />
        </Box>
        <ArrayInput fullWidth label="Агуулга" source="zodiacInformations" defaultValue={infoDefaults}>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <TextInput label="type" style={{ display: 'none' }} value="DAILY" source={getSource('zContentType')} disabled />
                    <SelectInput optionText={optionRenderer} label="Төрөл" style={{ minWidth: '200px' }} choices={ztags} source={getSource('ztagId')} />
                    <RichTextInput label="Тайлбар" source={getSource('description')} toolbar={[['bold', 'italic', 'underline', 'link'], [{ 'list': 'bullet' }]]} />
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput
          label="Төлөв"
          source="status"
          choices={CONTENT_STATUS}
        />
      </SimpleForm>
    </Create>
  );
}
