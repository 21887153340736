import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';
const SubscriptionChangeBtn = ({ record, subscriptionPlans }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [coupon, setCoupon] = useState('');
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(record.subscriptionPlanId);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`userSubscriptions/${record.userId}`, { data:{ subscriptionPlanId: subscriptionPlan, coupon, zodiac: record.zodiac } })
      .catch((e) => {
        console.log(e)
        notify('Error: code not activated', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
    <>
      <select value={subscriptionPlan} onChange={(ev) => setSubscriptionPlan(ev.target.value)}>
          {subscriptionPlans.map((type) => <option value={type.id}>{type.name}</option>)}
      </select>
      <input placeholder="coupon" value={coupon} maxLength={8} minLength={6} onChange={(ev) => setCoupon(ev.target.value.toUpperCase())}></input>
      <Button onClick={handleClick} label="Идэвжүүлэх" disabled={loading} />
    </>
  );
};

export default SubscriptionChangeBtn;
