import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  BooleanInput,
  EditButton,
  SelectInput,
  FunctionField,
  NumberInput,
  FormDataConsumer,
  BooleanField,
} from 'react-admin';
import { PLAN_CHOICES, PURCHASE_TYPES } from '../constants';

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const SubscriptionPlanList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField label="Хугацаа" source="type" />
      <TextField label="Үндсэн төрөл" source="mainType" />
      <FunctionField label="Үнэ" render={record => `${formatNumber(record.price)} MNT`} />
      <FunctionField label="Хямдрал" render={record => `${record.sale}%`} />
      <BooleanField label="Идэвхтэй эсэх" source="active"/>
      <BooleanField label="Санал болгосон эсэх" source="suggested"/>
      <EditButton />
    </Datagrid>
  </List>
);

export const SubscriptionPlanEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <SelectInput label="Хугацаа" source="type" choices={PLAN_CHOICES} />
      <SelectInput label="Үндсэн төрөл" source="mainType" choices={PURCHASE_TYPES} />
      <TextInput label="Гарчиг" source="title" />
      <NumberInput label="Үнэ" source="price" />
      <TextInput label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Хямдрал" source="sale" />
      <BooleanInput label="Идэвхтэй эсэх" source="active" />
      <BooleanInput label="Санал болгох эсэх" source="suggested" />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.suggested &&
          <TextInput label="Саналын текст" source="suggestionText" />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

export const SubscriptionPlanCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput label="Хугацаа" source="type" choices={PLAN_CHOICES} />
      <SelectInput label="Үндсэн төрөл" source="mainType" choices={PURCHASE_TYPES} />
      <TextInput label="Гарчиг" source="title" />
      <NumberInput label="Үнэ" source="price" />
      <TextInput label="Нэмэлт тайлбар" source="additionalDescription" />
      <NumberInput label="Хямдрал" source="sale" />
      <BooleanInput label="Идэвхтэй эсэх" source="active" />
      <BooleanInput label="Санал болгох эсэх" source="suggested" />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.suggested &&
          <TextInput label="Саналын текст" source="suggestionText" />
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
