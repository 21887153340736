import httpClient from './httpClient';
import objectToFormData from './objectToFormData';
import { stringify } from 'qs';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function createFormData(data) {
  const { file, imgFile, documentFile, homeworkFile, audioFile, ...rest } = data;
  const formData = objectToFormData(rest);
  if (file) {
    formData.append('file', file.rawFile);
  }
  if (imgFile) {
    formData.append('imgFile', imgFile.rawFile);
  }
  if (documentFile) {
    formData.append('documentFile', documentFile.rawFile);
  }
  if (homeworkFile) {
    formData.append('homeworkFile', homeworkFile.rawFile);
  }
  if (audioFile) {
    formData.append('audioFile', audioFile.rawFile);
  }
  return formData;
}

export default {
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: [field, order],
      page: page - 1, perPage,
      filter: params.filter,
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(
        headers
          .get('content-range')
          .split('/')
          .pop(),
        10,
      ),
    }));
  },
  importCSV: (resource, params) => {
    const url = `${apiUrl}/${resource}/csv`;
    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data:json }));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  get: (resource) =>
    httpClient(`${apiUrl}/${resource}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    const query = {
      filter: { id: params.ids },
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: [field, order],
      page: page - 1, perPage,
      filter: {
        ...params.filter,
        [params.target]: params.id,
      },
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(
        headers
          .get('content-range')
          .split('/')
          .pop(),
        10,
      ),
    }));
  },

  update: (resource, params) => {
    if (params.data.file || params.data.imgFile || params.data.documentFile || params.data.homeworkFile || params.data.audioFile || params.data.pictures) {
      const fd = createFormData(params.data);

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PUT',
        body: fd,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  patch: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
  put: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    // If we have files we need to form-data

    if (params.data.file || params.data.imgFile || params.data.documentFile || params.data.homeworkFile || params.data.audioFile || params.data.pictures) {
      const fd = createFormData(params.data);
      return httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: fd,
      }).then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }));
    }
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = { id: params.ids };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};
