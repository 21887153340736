import Toolbar from '@material-ui/core/Toolbar';
import React, { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateInput,
  Edit,
  EditButton,
  ExportButton,
  Filter,
  FunctionField,
  List,
  Loading,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import { PURCHASE_TYPES, SUBSCRIPTION_STATUSES, ZODIAC_CHOICES, getSignName } from "../constants";
import dataProvider from "../utils/dataProvider";
import SubscriptionChangeBtn from './SubscriptionChangeBtn';
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const fetchData = () => {
  return dataProvider.getList('subscriptionPlans', {
    pagination: {
      page: 1, perPage: 10,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.filter(item => item.active).map(item => {
      return {
        id: item.id,
        name: `${item.type} - ${item.mainType} (${formatNumber(item.price)})`,
      }
    });
  });
}
const UserIdFilter = (props) => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData().then(choices => {
      setSubscriptionPlans(choices);
      setLoading(false);
    });
  }, [])

  if (loading) return <Loading />;

  return (
    <Filter {...props}>
      <TextInput label="User Id" source="userId" />
      <TextInput label="User Code" source="code" alwaysOn />
      <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
      <TextInput label="Email" source="email" alwaysOn />
      <SelectInput
        label="Төлөв"
        source="status"
        emptyText="Бүгд"
        choices={SUBSCRIPTION_STATUSES}
      />
      <SelectInput
        label="Орд"
        source="zodiac"
        emptyText="Бүгд"
        choices={ZODIAC_CHOICES}
      />
      <SelectInput
        label="Төрөл"
        source="subscriptionPlanId"
        emptyText="Бүгд"
        choices={subscriptionPlans}
      />
    </Filter>
  )
};

const UserSubscriptionActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
    </Toolbar>
  );
};


const RowPagination = props => <Pagination rowsPerPageOptions={[12, 24, 36]} {...props} />;

export const UserSubscriptionList = props => {
  // const refresh = useRefresh();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetchData().then(choices => {
      setSubscriptionPlans(choices);
      setLoading(false);
    });
  }, [])

  if (loading) return <Loading />;

  return (
    <List {...props} perPage={12} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<UserIdFilter />} actions={<UserSubscriptionActions props={props} />}>
      <Datagrid optimized>
        <TextField source="id" />
        <FunctionField label="Орд" render={record => getSignName(record.zodiac)} sortable={false} />
        <ReferenceField label="Имайл" source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <TextField label="Утасны дугаар" source="user.phoneNumber" />
        <TextField label="Хэрэглэгчийн code" source="user.code" />
        <ReferenceField label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
          <TextField source="type" />
        </ReferenceField>
        <TextField label="Social" source="user.social" />
        <TextField source="status" />
        <SubscriptionChangeBtn subscriptionPlans={subscriptionPlans} />
        <EditButton />
      </Datagrid>
    </List>
  );
}
const optionRenderer = choice => `${choice.type} - ${choice.mainType}`;

export const UserSubscriptionEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Хэрэглэгчийн Нэр" source="userId" reference="users">
        <SelectInput disabled optionText="firstName" />
      </ReferenceInput>
      <DateInput label="Эхлэх өдөр" source="startDate" />
      <DateInput label="Дуусах өдөр" source="endDate" />
      <BooleanInput label="Reccuring" source="isReccuring" />
      <ReferenceInput label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
        <SelectInput optionText={optionRenderer} />
      </ReferenceInput>
      <SelectInput source="mainType" label="Yндсэн төрөл" choices={PURCHASE_TYPES} validate={required()} />
      <TextField label="Орд" source="zodiac" />
      <SelectInput source="status" lable="Төлөв" choices={[
        { id: 'PAID', name: 'PAID' },
        { id: 'FREE', name: 'FREE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]} />
    </SimpleForm>
  </Edit>
);

const userOptionRenderer = choice => `${choice?.firstName}, ${choice?.email}, ${choice?.phoneNumber || ''}`;

export const UserSubscriptionCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <ReferenceInput label="Хэрэглэгчийн Нэр" source="userId" reference="users" fullWidth>
        <AutocompleteInput optionText={userOptionRenderer} />
      </ReferenceInput>
      <DateInput label="Эхлэх өдөр" source="startDate" validate={required()} />
      <DateInput label="Дуусах өдөр" source="endDate" validate={required()} />
      <BooleanInput label="Reccuring" source="isReccuring" />
      <ReferenceInput label="Subscription" source="subscriptionPlanId" reference="subscriptionPlans">
        <SelectInput optionText={optionRenderer} />
      </ReferenceInput>
      <SelectInput source="mainType" label="Yндсэн төрөл" choices={PURCHASE_TYPES} validate={required()} />
      <SelectInput source="zodiac" label="Орд" choices={ZODIAC_CHOICES} validate={required()} />
      <SelectInput source="status" label="Төлөв" validate={required()} choices={[
        { id: 'PAID', name: 'PAID' },
        { id: 'FREE', name: 'FREE' },
        { id: 'INACTIVE', name: 'INACTIVE' },
      ]} />
    </SimpleForm>
  </Create>
);
