import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';

export const FeedbackList = props => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid optimized>
      <TextField label="id" source="id" />
      <TextField label="Нэр" source="name" />
      <TextField label="Емайл" source="email" />
      <TextField label="Санал хүсэлт" source="content" />
      <TextField label="Утас" source="phoneNumber" />
      <DateField label="Огноо" source="createdAt" locales="mn-MN" />
    </Datagrid>
  </List>
);

