import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
import { SOCIAL_TYPES, USER_TYPES } from '../constants';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  ImageField,
  EmailField,
  SimpleForm,
  TextInput,
  EditButton,
  FileField,
  FileInput,
  Filter,
  SelectInput,
  Pagination,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" />
    <TextInput label="First name" source="firstName" />
    <TextInput label="Last name" source="lastName" />
    <TextInput label="Email" source="email" />
    <TextInput label="Phone Number" source="phoneNumber" />
  </Filter>
);

const UserAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};
const RowPagination = props => <Pagination rowsPerPageOptions={[24, 48, 72]} {...props} />;

export const UserList = props => (
  <List {...props}  perPage={24} pagination={<RowPagination />} sort={{ field: 'id', order: 'DESC' }} filters={<TextFilter/>} bulkActionButtons={false} actions={<UserAction props={props} />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <TextField source="phoneNumber" />
      <TextField source="social" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput source="email" />
      <ImageField source="picture" title="Зураг" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <SelectInput label="Нэвтэрсэн Сошиал хаяг" source="social" choices={SOCIAL_TYPES} />
      <SelectInput label="Төрөл" source="type" choices={USER_TYPES} />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="phoneNumber" />
      <TextInput source="email" />
      <FileInput source="file" label="Зураг" accept="image/*">
        <FileField source="image" title="image" />
      </FileInput>
      <TextInput source="social" defaultValue="MANUAL" disabled={true}/>
    </SimpleForm>
  </Create>
);
