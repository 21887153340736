import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { ColorInput } from 'react-admin-color-input';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  ExportButton,
  Filter,
  EditButton,
  CreateButton,
  required, ArrayInput, SimpleFormIterator, FormDataConsumer, SelectInput,
  RichTextField,
} from 'react-admin';
import {ZCONTENT_TYPES} from "../constants";
import { Box } from '@material-ui/core';
const CodeFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Code" source="code" alwaysOn />
  </Filter>
);

const MoodActions = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    currentSort,
    total,
    exporter,
    basePath,
  } = props;

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
        maxResults={total}
      />
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const MoodList = props => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<CodeFilter />} actions={<MoodActions props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Код" source="code" />
      <TextField label="Нэр" source="name" />
      <RichTextField label="Icon" source="icon" />
      <TextField label="Өнгө" source="color" />
      <EditButton />
    </Datagrid>
  </List>
);

const MoodTitle = ({ record }) => {
  const { code } = record;
  return <span>Орд {record ? `"${code}"` : ''}</span>;
};

export const MoodEdit = props => (
  <Edit title={<MoodTitle />} {...props}>
    <SimpleForm>
      <TextField label="ID" source="id" />
      <TextInput label="Код" source="code" validate={[required()]} />
      <TextInput label="Нэр" source="name" validate={[required()]} />
      <TextInput label="Icon" source="icon" validate={[required()]} fullWidth multiline />
      <ColorInput label="Өнгө" source="color" validate={[required()]} helperText="opacity default: 0.12" />
      <ArrayInput fullWidth label="Ангилал" source="category">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) => {
              return (
                <>
                  <Box display="flex">
                    <SelectInput label="Сэдэв" source={getSource('category')} choices={ZCONTENT_TYPES} validate={required()} />
                    <TextInput label="Rank" source={getSource('rank')} type="number" validate={required()} />
                    <TextInput label="Color" source={getSource('color')} type="text" />
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const MoodCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextField label="ID" source="id" />
      <TextInput label="Код" source="code" validate={[required()]} />
      <TextInput label="Нэр" source="name" validate={[required()]} />
      <TextInput label="Icon" source="icon" validate={[required()]} fullWidth multiline />
      <ColorInput label="Өнгө" source="color" validate={[required()]} helperText="opacity default: 0.12" />
      <ArrayInput fullWidth label="Ангилал" source="category">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) => {
              return (
                <>
                  <Box display="flex">
                    <SelectInput label="Сэдэв" source={getSource('category')} choices={ZCONTENT_TYPES} validate={required()} />
                    <TextInput label="Rank" source={getSource('rank')} type="number" validate={required()} />
                    <TextInput label="Color" source={getSource('color')} type="text" />
                  </Box>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
