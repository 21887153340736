import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { ADMIN_ROLES } from '../constants/AdminUser';
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  BooleanField,
  BooleanInput,
  SelectInput,
  PasswordInput,
  Filter,
  CreateButton,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
  </Filter>
);

const AdminUserAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const AdminUserList = props => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<TextFilter/>} actions={<AdminUserAction props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Email" source="email" />
      <TextField label="Roles" source="role" />
      <TextField label="Last Login" source="lastLogin" />
      <BooleanField label="Enabled" source="enabled" />
      <EditButton />
    </Datagrid>
  </List>
);

const AdminUserTitle = ({ record }) => {
  const { id } = record;
  return <span>Admin User {record ? `"${id}"` : ''}</span>;
};

export const AdminUserEdit = props => (
  <Edit title={<AdminUserTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Email" source="email" />
      <PasswordInput source="password"/>
      <SelectInput source="role" choices={ADMIN_ROLES} />
      <BooleanInput source="enabled"/>
    </SimpleForm>
  </Edit>
);

export const AdminUserCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Email" source="email" />
      <TextInput label="Password" source="password" />
      <SelectInput source="role" choices={ADMIN_ROLES} />
      <BooleanInput source="enabled"/>
    </SimpleForm>
  </Create>
);
